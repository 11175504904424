@font-face {
  font-family: "latinoRumba";
  src: local("latinoRumba"), url(./fonts/latinoRumba.otf) format("truetype");
}

.sign {
  position: absolute;
  top: 5px;
  right: 5px;
}

* {
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: latinoRumba;
  color: rgb(245, 149, 194);
}

.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  // z-index: 2;
}
.modalContent {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modalHeader {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modalBody {
  padding: 2px 16px;
}

.modalFooter {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.header {
  padding-left: 100px;

  img {
    position: absolute;
    height: 136px;
    left: 34px;
  }
  h2 {
    font-size: 40px;
  }
  .bottom {
    position: relative;
    left: -11px;
  }
}

.section {
  .inputFields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  .item {
    border: 2px solid rgb(232, 0, 139);
    border-radius: 5px;
    max-width: 700px;
    padding: 10px;

    .picture {
      width: 150px;
      height: 150px;
      border: 1px solid rgb(102, 102, 102);
      border-radius: 5px;
    }
  }
}
